.contact-us {
    margin-top: 75px;
    display: flex;
    justify-items: space-evenly;
}
.contact-our-cleaning-service {
    margin: 0;
    padding: 45px;
    width: 50%;
    padding-left: 45px;
}
.contact-our-cleaning-service h1 {
    font-size: 4.5em;
    font-family: 'Roboto', sans-serif;
}
.contact-our-cleaning-service p {
    font-size: 1.45em;
    font-family: 'Roboto', sans-serif;
}
.contact-form {
    margin: 0;
    padding: 25px;
    width: 50%;
    
}
form {
    display: flex;
    flex-direction: column;
    height: 400px;
    font-family: 'Roboto', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}
form input {
    height: 50px;
    font-size: 1.5em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}
form textarea {
    height: 200px;
    font-size: 1.5em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}
form button {
    height: 50px;
    font-size: 1.5em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    background-color: #f2f2f2;
    font-family: 'Roboto', sans-serif;
}
form button:hover {
    background-color: #ccc;
    font-family: 'Roboto', sans-serif;
}
form input:focus {
    outline: none;
    border: 1px solid #ccc;
    font-family: 'Roboto', sans-serif;
}
form textarea:focus {
    outline: none;
    border: 1px solid #ccc;
    font-family: 'Roboto', sans-serif;
}
form button:focus {
    outline: none;
    border: 1px solid #ccc;
    font-family: 'Roboto', sans-serif;
}
form input::placeholder {
    font-family: 'Roboto', sans-serif;
}
.contact-snip{
    display: block;
    margin-top: 75px;
    font-family: 'Roboto', sans-serif;
    

}
.contact-snip h3 {
    font-size: 1.5em;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
.mailing-address {
    display: block;
    margin-top: 75px;
    width: 30vw;
}
a {
    text-decoration: none;
    color: white;
    font-family: 'Roboto', sans-serif;
}
a:hover {
    color: #ccc;
    font-family: 'Roboto', sans-serif;
}
.contact {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}
.contact-snip h3 {
    font-size: 1.5em;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
.contact-snip img {
    height: 25px;
    font-family: 'Roboto', sans-serif;
}
.filler {
    background-image: url(./images/filler-cleaner.jpg);
    background-position: 50% 50%;
    background-size: cover;
    height: 500px;
    display: block;
}
.filler-inside h1 {
    padding-top: 190px;
    
    font-size: 3em;
    color: #f2f2f2;
}
.filler-inside Link {
    color: white;
    
    padding-bottom: auto;
}
.filler-inside {
    width: 50vw;
    padding-left: 58px;
}
.filler-inside p {
    border: 1px solid black;
    padding: 1.2em 2.004em;
    background-color: black;
    color: white;
    width: 7vw;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .contact-us {
        flex-direction: column;
    }
    .contact-our-cleaning-service {
        width: 80%;
    }
    .contact {
        flex-direction: column;
    }
    .contact-form {
        width: 80%;
    }
    .mailing-address {
        width: 80%;
    }
    .filler {
        height: 300px;
    }
    .filler-inside {
        width: 80%;
        font-size: .6em;
    }
    .filler-inside h1 {
        padding-top: 50px;
    }
    .filler-inside p {
        width: 15vw;
        font-size: 1em;
    }
    .filler-inside p a {
        color: white;
    }
}