.title-container-services {
    font-size: 2em;
    margin-left: 150px;
    text-align: left;
    color: #1D3557;
}
.services {
    margin-left: 75px;
    margin-right: 75px;
    margin-top: 75px;
    padding: 15px;
}
.standard-cleaning {
    
    display: flex;
    justify-content: space-evenly;
}
.cleaned-home-1 {
    background-image: url('https://memorialmaids.nyc3.digitaloceanspaces.com/images/clean-home-1.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    width: 100%;
    display: flex;
    
}
.standard-cleaning-checklist {
    
    padding: 50px;
    height: fit-content;
    width: 50%;
    font-family: 'Roboto', sans-serif;
    
    background-color: #f2f2f2;
}
.standard-cleaning-checklist h1 {
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
}
.standard-cleaning-checklist p {
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
}
.standard-cleaning-checklist ul {
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;
}
.standard-cleaning-checklist li {
    font-size: 1.1em;
    font-family: 'Roboto', sans-serif;
}
.standard-cleaning-checklist li::before {
    content: "✔";
    color: #f2f2f2;
    font-size: 1.5em;
    margin-right: 10px;
}
.deep-cleaning {
    display: flex;
    margin-top: 150px;
    
}
.deep-cleaning-checklist {

    padding: 50px;
    height: fit-content;
    width: 30%;
    font-family: 'Roboto', sans-serif;
    background-color: #f2f2f2;
}
.deep-cleaning-checklist h1 {
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
}
.deep-cleaning-checklist p {
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
}
.deep-cleaning-checklist ul {
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;
}
.deep-cleaning-checklist li {
    font-size: 1.1em;
    font-family: 'Roboto', sans-serif;
}
.deep-cleaning-checklist li::before {
    content: "✔";
    color: #f2f2f2;
    font-size: 1.5em;
    margin-right: 10px;
}
.cleaned-home-2 {
    background-image: url('https://memorialmaids.nyc3.digitaloceanspaces.com/images/clean-home-2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    width: 80%;
    display: flex;

}
.move-in-out-cleaning {
    display: flex;
    margin-top: 150px;
}
.move-in-out-cleaning-checklist {
    margin: 0px;
    padding: 50px;
    height: fit-content;
    width: 30%;
    font-family: 'Roboto', sans-serif;
   
    background-color: #f2f2f2;
}
.move-in-out-cleaning-checklist h1 {
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
}
.move-in-out-cleaning-checklist p {
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
}
.move-in-out-cleaning-checklist ul {
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;
}
.move-in-out-cleaning-checklist li {
    font-size: 1.1em;
    font-family: 'Roboto', sans-serif;
}
.move-in-out-cleaning-checklist li::before {
    content: "✔";
    color: #f2f2f2;
    font-size: 1.5em;
    margin-right: 10px;
}
.cleaned-home-3 {
    background-image: url('https://memorialmaids.nyc3.digitaloceanspaces.com/images/clean-home-3.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    width: 80%;
    display: flex;
}
.rental-home-cleaning {
    display: flex;
    margin-top: 150px;
    
}
.rental-home-cleaning-checklist {
    margin: 0px;
    padding: 50px;
    height: fit-content;
    width: 30%;
    font-family: 'Roboto', sans-serif;
  
    background-color: #f2f2f2;
}
.rental-home-cleaning-checklist h1 {
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
}
.rental-home-cleaning-checklist p {
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
}
.rental-home-cleaning-checklist ul {
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;
}
.rental-home-cleaning-checklist li {
    font-size: 1.1em;
    font-family: 'Roboto', sans-serif;
}
.rental-home-cleaning-checklist li::before {
    content: "✔";
    color: #f2f2f2;
    font-size: 1.5em;
    margin-right: 10px;
}
.cleaned-home-4 {
    background-image: url('https://memorialmaids.nyc3.digitaloceanspaces.com/images/clean-home-4.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    width: 80%;
    display: flex;
}
.carpet-cleaning {
    display: flex;
    margin-top: 150px;
    
}
.carpet-cleaning-checklist {
    margin: 0px;
    padding: 75px;
    height: fit-content;
    width: 30%;
    font-family: 'Roboto', sans-serif;
    padding-left: 50px;
    padding-top: 50px;
    background-color: #f2f2f2;
}
.carpet-cleaning-checklist h1 {
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
    
}
.carpet-cleaning-checklist p {
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
}
.cleaned-home-5 {
    background-image: url('https://memorialmaids.nyc3.digitaloceanspaces.com/images/clean-home-5.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    width: 80%;
    display: flex;
}
.organization-services {
    display: flex;
    margin-top: 150px;
   
    height: fit-content;
}
.organization-description {
    margin: 0px;
    padding: 75px;
    height: fit-content;
    padding-bottom: 100px;
    width: 30%;
    font-family: 'Roboto', sans-serif;
    padding-left: 50px;
    padding-top: 50px;
    background-color: #f2f2f2;
}
.organization-description h1 {
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
}
.organization-description p {
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
}
.organization-description ul {
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;
}
.organization-photo {
    margin: 0px;
    padding: 75px;
    background-image: url('https://memorialmaids.nyc3.digitaloceanspaces.com/images/organize-photo.png');
    width: 60%;
    height: 70vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    font-family: 'Roboto', sans-serif;
    padding-left: 50px;
    padding-top: 50px;
}
.space {
    height: 20px;
}

@media only screen and (max-width: 600px) {

    .services {
        margin-left: 20px;
        margin-right: 20px;

    }

    .title-container-services {
        margin-left: 0px;
    }
    .standard-cleaning, .deep-cleaning, .move-in-out-cleaning, .rental-home-cleaning, .carpet-cleaning, .organization-services {
        flex-direction: column;
    }
    .cleaned-home-1, .cleaned-home-2, .cleaned-home-3, .cleaned-home-4, .cleaned-home-5, .organization-photo {
        height: 30vh;
        width: 100%;
        padding: 0px;
    }
    .standard-cleaning-checklist, .deep-cleaning-checklist, .move-in-out-cleaning-checklist, .rental-home-cleaning-checklist, .carpet-cleaning-checklist, .organization-description {
        width: 80%;
        height: fit-content;
        padding: 30px;
    }
    .deep-cleaning, .organization-services {
        margin-bottom: 20vh;
    }
  

  
    
}