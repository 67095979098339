.admin {
    display: flex;
    margin-top: 120px;
    
}
.title-admin {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    width: 60%;
    
}

.login {
    width: 235px;
    height: 250px;
   
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    
}
.login input {
    margin: 10px;
    width: 200px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
}
.login button {
    margin: 10px;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    background-color: #ccc;
    cursor: pointer;
}
.admin-button {
    background-color: #333333;
    color: #f5f5f5;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}
.admin-button:hover {
    background-color: #616161;
}

.admin-header {
    
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}
.admin-bubble button {
    background-color: inherit;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;
    color: black;
}
.me:hover {
    color: blueviolet;
}
.account-menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    z-index: 100;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
}
.account-menu-header i{
    float: right;   
    margin: 10px;
    cursor: pointer;


}
.account-menu-container {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    
}
.account-menu button {
    background-color: grey;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;
    color: white;
}