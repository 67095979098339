.about {
    margin-top: 75px;
    display: flex;
    justify-items: space-evenly;
}
.about-our-cleaning-service {
    margin: 0;
    padding: 75px;
}
.about-us-img {
    width: 380vw;
    padding: 75px;
}
.about h1 {
    font-size: 3.5em;
    font-family: 'Roboto', sans-serif;
}
.about-us-img img{
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 600px) {

    .about {
        margin-top: 75px;
        display: block;
        justify-items: space-evenly;
    }

    .about-us-img {
        display: flex;
        width: 80%;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
    }
    
    
}

