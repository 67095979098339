.error {
    font-size: 1.5rem;
    text-align: center;
    margin: 2rem 0;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30rem;

}
.links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}
