.calendar {
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
}

.calendar-header {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}

.calendar-heading {
    display: flex;
    justify-content: center;
}
.calendar-heading h2{

    margin: 0;
    margin-top: 15px;

}

button p { 
    background-color: #000000;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px black;
    color: white;
}

button p:hover{
    color: black;
    background-color: white;

}

.tools {
    
    height: 100%;
    display: flex;
    
  }
  
.tools button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    border: none;
  }
  
.tools button i:hover {
    color: #99cccc;
  }
  

.calendar-body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.table-header {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.weekday {
  width: 100px;
  text-align: center;
}

.table-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}

.calendar-day {
  display: flex;
  width: 52px;
  height: 50px;
  position: relative;
  justify-content: center;
  border: 1px solid #a6a6a6;
}

.calendar-day:hover {
  background-color: rgba(0,0,0,.10);
}

.calendar-day p {
  position: absolute;
  
  color: #a6a6a6;
}

.current p {
  color: #000000;
}

.selected p {
  color: #cc0000;
  font-weight: bold;
}