nav {
    display: flex;
    justify-content: space-between;
    margin: 0;
    position: fixed;
    width: 100%;
    overflow: hidden;
    top: 0;
    z-index: 1;
    padding-top: 20px;
    padding-bottom: 20px;
}
.menu {
  position: absolute;
  z-index: 1;
  margin-top: 9vh;
  text-align: center;
  width: 100%;
  flex-direction: column;
}
.menu a {
  margin-bottom: 45px;
  font-size: 1.5em;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 1em 2em;
  width: 35%;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  color: black;
  background-color: rgb(250, 250, 250);
  transition: all .3s ease-in-out;
}
.sticky {
    background-color: #f2f2f2;
  }

.left-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 33.33%;
    font-size: 1.2em;
  }
.left-menu a {
    color: black;
    text-decoration: none;
    padding: 0 12px;
  }
.left-menu a:hover {
    color: #323532bb;;
    text-decoration: underline;
  }

.middle-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.33%;
   
}
.middle-menu img{
    width: 150px;
    height: 50px;
    padding: 10px;
    
  }
.right-menu {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 33.33%;

  }
 .right-menu a {
    
    text-decoration: none;
    margin: 0 10px;
  }
.right-menu a:hover {
    opacity: .8;
    
  }
.bookButton {
    border: 1px solid black;
    padding: 1.2em 2.004em;
    background-color: black;
    color: white;
    margin-right: 15px;
    width: fit-content;
    
}

.sandwich {
    display: none;
}
.schedule {
    display: none;
}

@media only screen and (max-width: 600px) {
    .left-menu {
        display: none;
    }
    .sandwich {
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33.33%;
        padding-right: 15px;
    }

    .middle-menu {
        width: 33.33%;
        justify-content: center;
    }
    .right-menu {
        display: none;
    }
    .schedule {
        display: flex;
        align-items: center;
        justify-content: right;
        width: 33.33%;
        padding-right: 15px;
    }
    .schedule i{
        font-size: 2em;
        margin-bottom: auto;
        margin-top: auto;
        color: black;

    }

    .right-menu a {
        margin: 0;
        padding: 15px
    }
    .bookButton {
        margin-right: 0;
    }
    .burger {
        background-color: transparent;
        border: none
    }
    .menu-btn {
      position: relative;
      display: flex;
      align-items: center;
      width: 33.33%;
      height: 70px;
      margin-left: 2vw;
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;
      transition: all .5s ease-in-out;
      /* border: 3px solid #fff; */
  }

  .menu-btn__burger {
      width: 50px;
      height: 2.5px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px #808080;
      background-color: black;
      transition: all .5s ease-in-out;
  }

      .menu-btn__burger::before,
      .menu-btn__burger::after {
          content: '';
          position: absolute;
          width: 50px;
          height: 2.5px;
          background: #fff;
          border-radius: 5px;
          box-shadow: 0 2px 5px #808080;
          background-color: black;
          transition: all .5s ease-in-out;
      }

      .menu-btn__burger::before {
          transform: translateY(-16px);
      }

      .menu-btn__burger::after {
          transform: translateY(16px);
      }
  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
      transform: translateX(-50px);
      background: transparent;
      box-shadow: none;
  }

      .menu-btn.open .menu-btn__burger::before {
          transform: rotate(45deg) translate(35px, -35px);
      }

      .menu-btn.open .menu-btn__burger::after {
          transform: rotate(-45deg) translate(35px, 35px);
      }

    
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 1280px) {

  .left-menu {
    font-size: .68em;
  }

}