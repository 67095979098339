.all-book-now {
    display: flex;
    flex-direction: column;
    margin-top: 140px;
    align-items: center;

}
hr {
    margin-top: 15px;
    width: 50%;
    border: 1px solid #e5e5e5;
}
label {
    font-size: 1.6em;
    font-family: 'Roboto', sans-serif;
    padding-left: 10px;
}
.toogle {
    display: flex;
    padding: 10px;
    
}
.toogle h1 {
    font-size: 2.6em;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    margin-top: auto;
    margin-bottom: auto;
}    

input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .toggle-text {
    font-size: 1.6em;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .toggle-text {
    width: 50%;
    height: 50px;
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .toggle-bg { 
    display: relative;
    z-index: 0;
    height: 100%;
  }
  
  .dot {
    cursor: pointer;
    text-indent: -9999px;
    width: 300px;
    height: 80px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }
  
  .dot:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 180px;
    height: 90px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
  
  input:checked + .dot {
    background: #bada55;
    
  }
  
  input:checked + .dot:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  
  .dot:active:after {
    width: 130px;
  }

.new-user {
    
    border: 1px solid #e5e5e5;
    border-radius: 9px;
    background-color: aliceblue;
    width: fit-content;
    padding: 30px;
    height: auto;

}

.new-user h2, .existing-user h2{
    font-size: 2.6em;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin: 20px;
   
}
.new-title {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.new-user p, .existing-user p {
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    padding-left: 0px;

}
.new-user .form input {
    margin: 0;
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin: 2px;

   
}
form {
    padding: 10px;
    height: fit-content;

}

form input {
    width: 100%;
}

.existing-user form input {
    margin: 0;
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin: 2px;
    width: 100%;
}
.new-user .form input[type="submit"] {
    background-color: #EDB88B;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
}
.user-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
    width: 100%;
    height: fit-content;
}
input {
    width: 80%;
}
.new-user .form, .existing-user form {
    background-color: white;
    margin: 20px;
    margin-top: 0;
    border-radius: 9px;
    padding: 20px;
    height: fit-content;
    width: fit-content;
    
}

.new-user .form p {
    padding: 10px;
}
.existing-user {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: fit-content;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #e5e5e5;
    background-color: whitesmoke;
    border-radius: 9px;
    padding: 15px;


}
Link {
    font-size: .8em;
    padding: 0;
    margin: 0;
}
 

fieldset {
    border: none;
    display: block;
}
label {
    padding: 10px;
}
.form button, .new-user button, .existing-user button {
    
    
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
}
.new-user button {
    font-size: 1.5em;
    font-family: 'Roboto', sans-serif;
    
}

 

.new-user button:hover, button:hover {
   
    cursor: pointer;
}

.city-state {
    display: inline-flex;
    width: fit-content;
}
.city-state p {
    margin-left: 14px;

}

.appointmentDatesAvailable {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
    width: 20%;
    height: fit-content;
}


@media only screen and (max-width: 480px) {
    
    .book-now {
        flex-direction: column;
        align-items: center;

    }
    body {
        background-color: white;
    }
    .new-user {
        width: 90%;
        flex-direction: column;
        margin: 0;
        margin-bottom: 10vw;
    }
    .existing-user {
        width: 90%;
    }
    .new-user .form, .existing-user form {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .new-user .form input [type="submit"], .existing-user form input [type="submit"] {
        width: 100%;
    }
    .new-user form p {
        padding: 0;
    }
    .new-user h2, .existing-user h2 {
        font-size: 2em;
    }
    .new-user p, .existing-user p {
        font-size: 1.2em;
    }
    .new-user .form input {
        width: 90%;
        
    }
     .form button {
        width: unset;
    }
    .sign-on {
        display: flex;
       
    }
        
   
    
    
}
@media only screen and (max-width: 600px) {
    body {
      background-color: white;
    }
  }
@media only screen and (max-width: 768px) {
    body {
      background-color: white;

    }
 
  }
@media only screen and (max-width: 1024px) {
    body {
      background-color: white;
    }
    .new-user {
        width: 60%;
        flex-direction: column;
        margin: 0;
        margin-bottom: 10vw;
    }
    .existing-user {
        width: 35%;
        height: fit-content;

    }
    .user-form {
        width: 100%;
    }
  
    .existing-user .form {
        width: 100%;
    }
    input {
        width: 100%;
    }
    .address {
         padding-top: 10px;
         padding-bottom: 25px;

    }
    .name {
        margin-bottom: 100px;
    }
    
    
    
  }
@media only screen and (max-width: 1280px) {
    body {
      background-color: white;
    }
  }
@media only screen and (max-width: 1440px) {
    body {
      background-color: white;
    }
  }
@media only screen and (max-width: 1600px) {
    body {
      background-color: white;
    }
  }
@media only screen and (max-width: 1920px) {
    body {
      background-color: white;
    }
    .new-user .form {
        width: 100%;
    }
    .existing-user .form {
        width: 90%;
    }

  }
@media only screen and (max-width: 2560px) {
    body {
      background-color: white;
    }
  }
