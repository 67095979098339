
.slideshow {
    background-image: url('https://memorialmaids.nyc3.digitaloceanspaces.com/images/slideshowCover.png');
    background-position: 70% 30%;
    background-size: cover;
    height: 680px;
    margin: 0;
    display: flex;
    font-family: 'Roboto', sans-serif;
}
.slideshow-text{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    font-family: 'Roboto', sans-serif;

}
.slideshow-text h1 {
    font-size: 4.5em;
    font-family: 'Roboto', sans-serif;
    font-weight: 250;
}
.slideshow-button {
    border: .1px solid black;
    width: 13vw;
    background-color: black;
    height: 65px;
    font-size: 1.7em;
    color: white;
    margin-top: 28px;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
    
    display: flex;

}
.slideshow-button:hover {
    opacity: .8;
    border: .1px solid black;
    font-family: 'Roboto', sans-serif;
}
.slideshow-button p {
    margin: auto;
    font-family: 'Roboto', sans-serif;
}
.cleaning-services-perks {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    
}
.perk 
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33.33%;
    height: 400px;
    background-color: #f2f2f2;
    font-family: 'Roboto', sans-serif;
}
.perk p {
    padding-left: 3vw;
    padding-right: 3vw;
}
.perk img {
    width: 100px;
    height: 100px;
    margin: 0 10px;
}
.cleaning-email-request {
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
    font-size: 1.5em;
    font-family: 'Roboto', sans-serif;
  
}
.cleaning-email-request::before {
    content: "";
    background-image: url("https://memorialmaids.nyc3.digitaloceanspaces.com/images/form-background.jpg");
    background-position: 40% 50%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    
}

.cleaning-email-request h1 {
    font-size: 3em;
    font-family: 'Roboto', sans-serif;
   
    border-radius: 5px;
   
}
.cleaning-email-request form input {
    width: 90%;
}
.cleaning-email-request form {
    margin-top: 20px;
    margin-bottom: 20px;
}
.contact-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 600px;
    background-color: #f2f2f2;
    font-family: 'Roboto', sans-serif;
}
.menu {
    display: none;
}
.menu-wrapper {
    display: none;
}

@media only screen and (max-width: 390px) {
    
   
   
    
    
}
@media only screen and (max-width: 600px) {
    .slideshow {
        background-position: 50% 50%;

    }
    .slideshow-text {
        padding-left: 20px;
    }
    .cleaning-services-perks {
        flex-direction: column;
        height: 100%;
        padding: 20px;
    }
    .perk {
        width: 100%;
        height: 300px;
    }
    .cleaning-email-request {
        flex-direction: column;
        height: fit-content;
        padding: 20px;
        padding-bottom: 50px;
        
    }
    .cleaning-email-request h1 {
        font-size: 2.5em;
    }
    .contact-info {
        flex-direction: column;
        height: 100%;
    }
    iframe {
        padding: 20px;
        width: auto;
    }


    
  }
@media only screen and (max-width: 768px) {
    
 
  }
@media only screen and (max-width: 1024px) {
    
  }
@media only screen and (max-width: 1280px) {
    
  }
@media only screen and (max-width: 1440px) {
   
  }
@media only screen and (max-width: 1600px) {
   
  }
@media only screen and (max-width: 1920px) {
    

  }
@media only screen and (max-width: 2560px) {
    
  }
