.pet {
  height: 25px;
  width: 25px;
  
} 
.add-on {
  height: 25px;
  width: 25px;
  margin-left: 10px;
  margin-right: 10px;
}