.dashboard {
    margin: 125px;
}
.user-info {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: fit-content;
    
    
}
.user-address {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: fit-content;
    margin-bottom: 2em;

}
.user-calendar {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 400px;
    height: fit-content;


}
.first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
    width: 100%;
    height: fit-content;
}
.second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
}
.invoice {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 400px;
    height: 500px;
}
.modal {
    background-color: beige;
    padding: 20px;
   
}
.chat {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 400px;
    height: 400px;
}

#add-on {
    width: 7em;
    height: 5em;
    margin: 10px;
}
.add-ons-row-1, .add-ons-row-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}
.rewards {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 100%;
    height: fit-content;
}
.rewards-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 10px;
}
.reward { 
    font-size: 2.5em;
}
.fourth-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
}
.refer-a-friend {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 100%;
    height: fit-content;
}
.feedback {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 100%;
    height: fit-content;
}
.feedback-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 50px;
}
.feedback-buttons i {
     font-size: 2em;
}