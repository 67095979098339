.faqs {
    display: flex;
    justify-content: space-evenly;
    margin-top: 150px;
    padding-right: 50px;
}
.faq-title {
    font-size: 2em;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 200vw;
}

.faq {
   padding: 50px;
}
.faq h4 {
    font-size: 1.5em;
    font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width: 600px) {

    .faqs {
        flex-direction: column;
    }
    .faq-title {
        width: 100%;
    }
    
}