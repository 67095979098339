.our-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    font-family: 'Roboto', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
}
 .our-info h3{
    display: flex;
    justify-content: center;
    
}
.our-info-section {
    text-align: center;
    
}
.more-info {
    display: block;
    padding-left: 150px;
    font-size: 2 em;
  
}
a {
    text-decoration: none;
    color: blue;
}

@media only screen and (max-width: 480px) { 
    .our-info {
        margin-top: 75px;
        margin-bottom: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 400px;
        font-family: 'Roboto', sans-serif;
        padding-left: 50px;
        padding-right: 50px;
    }
    .our-info h3{
        display: flex;
        justify-content: center;
        
    }
    .our-info-section {
        text-align: center;
        
    }
    .more-info {
        display: block;
        font-size: 2 em;
        padding-left: 15px;

        
    }
}